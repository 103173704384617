var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "side-nav", class: { open: _vm.isMenuOpen } },
    [
      _vm.isAuthenticated ? _c("ProfilePicture") : _vm._e(),
      _vm.isAuthenticated ? _c("Email") : _vm._e(),
      _vm.isAuthenticated ? _c("RoleName") : _vm._e(),
      _vm.isAuthenticated ? _c("UserCountries") : _vm._e(),
      _vm.isAuthenticated
        ? _c("Menu", { attrs: { items: _vm.menuLinks } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }