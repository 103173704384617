<template>
  <div class="side-nav" :class="{ open: isMenuOpen }">
    <ProfilePicture v-if="isAuthenticated" />
    <Email v-if="isAuthenticated" />
    <RoleName v-if="isAuthenticated" />
    <UserCountries v-if="isAuthenticated" />
    <Menu :items="menuLinks" v-if="isAuthenticated" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import authentication from "../../authentication";

export default {
  name: "SideNavigation",
  components: {
    Menu: () => import(/* webpackPrefetch: true */ "../common/Menu"),
    ProfilePicture: () =>
      import(/* webpackPrefetch: true */ "../common/ProfilePicture"),
    Email: () => import(/* webpackPrefetch: true */ "../common/Email"),
    RoleName: () => import(/* webpackPrefetch: true */ "../common/RoleName"),
    UserCountries: () =>
      import(/* webpackPrefetch: true */ "../common/UserCountries"),
  },
  data: () => ({
    menuLinks: [
      {
        name: "Formulation Mapping",
        path: "/formulation-mapping",
      },
      {
        name: "Product Details",
        path: "/product-details",
      },
      {
        name: "Reports",
        path: "/reports",
      },
      {
        name: "UAM",
        path: "/uam/users",
      },
      {
        name: "Logout",
        path: "",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isMenuOpen: "GET_IS_MENU_OPEN",
    }),
    isAuthenticated() {
      return authentication.isAuthenticated();
    },
  },
};
</script>

<style lang="scss" scoped>
.side-nav {
  position: fixed;
  width: 0;
  height: 100vh;
  background-color: #e8e8e8;
  overflow: hidden;
  transition: width 0.5s ease;
  z-index: 1;
  .profile-username {
    margin: 0px 10px 10px 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    word-break: break-all;
    text-align: center;
    width: 210px;
  }
}
.side-nav.open {
  width: 230px;
  border-right: 1px solid #476f84;
  z-index: 9;
}
</style>
